import { EventRescueConfigurationType, MonitorType } from '../../internal-communication'
import type { CaughtException, UserContext } from '../common'
export * from './validators/ChannelConfiguration.validator'

export const PATHS = {
    base: 'admin/config/channels'
}

// :: Listing

export type ValidationType = 'warning' | 'success' | 'error' | 'info'

export type ChannelValidationStatus = {
    type: ValidationType
    channelName?: {
        empty?: boolean
        invalid?: boolean
    }
    companyAssigment?: {
        noCompanyAssigned?: boolean
        notAllCompanyWereAssigned?: boolean
    }
    companySegmentation?: {
        missingSome?: boolean
        missingAll?: boolean
    }
    menuDefinition?: {
        hasDuplicatedCompanies?: boolean
        hasNonValidCompanies?: boolean
        hasMissingEventId?: boolean
        hasDuplicatedEventId?: boolean
        hasMissingLink?: boolean
        hasInvalidMenuType?: boolean
        hasMissingDescription?: boolean
        hasEmptyMenu?: boolean
        hasCompanyNotUsed?: boolean
        noMenuDefined?: boolean
    }
    messageValidation?: {
        hasEmptyMessages?: boolean
        hasMissingTags?: boolean
    }
    workingHourValidation?: {
        hasInvalidTime?: boolean
        hasOverlapedTime?: boolean
        hasEmptyTime?: boolean
        hasPeriodInconsistencies?: boolean
        hasDuplicatedCompanyUsage?: boolean
        hasDataInconsistencies?: boolean
        hasCompaniesUsing24Hours?: boolean
        hasNoWorkingHoursDefinition?: boolean
    }
    extraValidation?: {
        hasUseGoBack?: boolean
    }
}

export type ChannelConfigInfo = {
    id: string
    name: string
    phone: string
    status: ChannelValidationStatus
    draft?: boolean
    published?: boolean
}

export type ChannelInfoListing = {
    items: ChannelConfigInfo[]
}

export type ChannelConfigListingRequest = {
    user?: UserContext
    channelName?: string
    channelNumber?: string
}
export type ChannelConfigListingResponse = ChannelInfoListing

// :: General Tab

export type CompanyChannelConfig = {
    companyId: number
    internal?: {
        segment?: boolean
        segmentations?: string[]
        name?: string
        email?: string
        reference?: string
        generalConfig?: {
            groupEvent?: string
            typeEvent?: string
            companyIdForTicketCreation?: number
            servicesBySegmentation?: {
                on: string
                servicesId: string[]
            }[]
        }
    }
    usesPreSaleNotification?: boolean
    showAttendantName?: boolean
    serviceBySegmentation: string[]
    segments: string[]
}

export type RobotRescueMessages = {
    rescue?: string
    goodbye?: string
}

export type SelfRescueConfig = {
    active: boolean
    type: MonitorType
    expireIn: number
    robotRescueMessages?: RobotRescueMessages
    eventRescueConfiguration?: EventRescueConfigurationType
}

export type SegmentationChannelConfig = {
    code: number
    channel: string
    description: string
    group: string
}

export type ServiceBySegmentation = {
    on: string
    servicesId: string[]
}

export type GeneralChannelConfig = {
    channelName?: string
    channelPhone: string
    useSegments: boolean
    segmentation: string[]
    companies: CompanyChannelConfig[]
    selfRescueConfig?: SelfRescueConfig
}

// :: Menu Tab

export enum ChannelMenuType {
    UNKNOWN = 0,
    EVENT = 1,
    LINK = 2,
    ROBOT = 3,
    SUB_MENU = 4,
    INFORMATION = 5
}

export type ChannelMenuEventOption = {
    id: string
    caption: string
    group: string
    type: string
}

export type SourceAndMediaType = { id: number; name: string }

export type ChannelSourceAndMediaEventOption = {
    source: SourceAndMediaType
    medias: SourceAndMediaType[]
}

export type ChannelMenuEntry = {
    _id?: string
    id: string
    subServices: string[]
    isSubService: boolean
    type: ChannelMenuType
    link?: string
    positiveButton?: string
    negativeButton?: string
    eventId?: string
    message?: string
    description: string
    segmentations?: string[]
    serviceInfo?: ServiceInfo[]
}

export type ServiceInfo = { companyId: number; menuId: string }

export type ChannelMenuItem = {
    id: number
    companies: number[]
    entries: ChannelMenuEntry[]
    segmentations: string[]
}

export type ChannelMenuConfig = {
    items: ChannelMenuItem[]
}

// :: Messages Tab

export type ChannelMessagesConfig = {
    greetings: string
    companySegmentation: string
    companySelection: string
    departmentSelection: string
    newTicket: string
    outOfWorkingHoursNewTicket: string
    attendenceContinuation: string
    identityConfirmation: string
    offlineSystem: string
    invalidOption: string
} & RobotRescueMessages

// :: Times Tab

export type HourMinuteTime = { h: number; m: number }

export type PeriodTime = {
    startTime?: HourMinuteTime
    endTime?: HourMinuteTime
}

export type ChannelWeekWorkingHours = {
    sunday?: PeriodTime[]
    monday?: PeriodTime[]
    tuesday?: PeriodTime[]
    wednesday?: PeriodTime[]
    thursday?: PeriodTime[]
    friday?: PeriodTime[]
    saturday?: PeriodTime[]
}

export type ChannelCompaniesWorkingHours = {
    id: number
    companies: number[]
    week: ChannelWeekWorkingHours
}

export type ChannelWorkingHoursConfig = {
    entries: ChannelCompaniesWorkingHours[]
}

// :: Extras Tab

export type ChannelExtrasConfig = {
    useGoBack: boolean
}

// :: Save Form

export type SaveChannelFormRequest = {
    user?: UserContext
    id: string
    data: ChannelConfig
    publish?: boolean
    timezone?: string
}

export type SaveChannelFormResponse = {
    status: number
    message?: string[]
    duration: number
}

// :: Discard Form

export type DiscardChannelFormRequest = {
    user?: UserContext
    id: string
    returnForm?: boolean
}

export type DiscardChannelFormResponse = {
    status: number
    message?: string[]
    duration: number
    form?: ChannelConfigResponse
}

// :: Hole Configuration

export type ChannelCompanyOption = {
    id: number
    name: string
    internal: {
        segment?: boolean
        segmentations?: string[]
        name?: string
        email?: string
        reference?: string
        generalConfig?: {
            groupEvent?: string
            typeEvent?: string
            showAttendantName?: boolean
            usesPreSaleNotification?: boolean
            companyIdForTicketCreation?: number
            servicesBySegmentation?: {
                on: string
                servicesId: string[]
            }[]
        }
    }
    eventOptions?: string[]
}

export type ChannelConfig = {
    general: GeneralChannelConfig
    menus: ChannelMenuConfig
    messages: ChannelMessagesConfig
    workingHours: ChannelWorkingHoursConfig
    extras: ChannelExtrasConfig
}

export type ChannelConfigRequest = {
    user?: UserContext
    id: string
}

export type ChannelConfigResponse = ChannelConfig & {
    id: string
    draft: boolean
    companyOptions: ChannelCompanyOption[]
    menuEventOptions: ChannelMenuEventOption[]
    sourceAndMediaEventOptions: ChannelSourceAndMediaEventOption[]
}

// :: Message Broker Topics

export const Topics = {
    listing: 'syo-whatsapp-bus-admin-channels-listing',
    form: 'syo-whatsapp-bus-admin-channels-form',
    saveForm: 'syo-whatsapp-bus-admin-channels-saveForm',
    discardForm: 'syo-whatsapp-bus-admin-channels-discardForm'
}

// :: Main Request

export type Request = {
    user?: Record<string, unknown>

    query?: {
        listing?: ChannelConfigListingRequest
        form?: ChannelConfigRequest
    }

    mutation?: {
        form?: SaveChannelFormRequest
        discard?: DiscardChannelFormRequest
    }
}

export type Response = {
    query?: {
        listing?: ChannelConfigListingResponse
        form?: ChannelConfigResponse
    }

    mutation?: {
        form?: SaveChannelFormResponse
        discard?: DiscardChannelFormResponse
    }
}

export interface CommunicationAPI {
    send(request: Request): Promise<Response | CaughtException>
}
